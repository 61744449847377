import { compose } from 'redux';
import { connect } from 'react-redux';
import { memo, useCallback, useState, useMemo, useEffect } from 'react';

import {
  getToolGroupsFromState,
  getToolSuppliersFromState,
  getToolsSearchResultFromState,
  getToolTopConnectorsFromState,
  getToolBottomConnectorsFromState,
} from 'features/projects/tool/tool.selector';

import {
  requestToolsSearch,
  toolPickerModalOnLoad,
  requestRefreshToolsWithM3,
  requestToolGroupsByCategory,
} from 'features/projects/tool/tool.actions';

import { EMPTY_LIST, EMPTY_MAP } from 'app/app.constants';
import { getSummarizedDataStateFromState } from 'app/app.selectors';
import { TOOL_ACTIONS } from 'features/projects/tool/tool.constants';
import { ToolCategoryType } from 'features/projects/tool/tool.constants';
import ToolPickerModal from 'features/projects/tool/components/ToolPickerModal';
import { Filters } from 'features/projects/tool/components/ToolPickerModalFilters';

const toolCategories = [
  { label: 'MWL Tools', value: ToolCategoryType.MWL },
  { label: 'EWL Tools', value: ToolCategoryType.EWL },
  { label: '3rd party', value: ToolCategoryType.ThirdParty },
  { label: 'Tool Assemblies', value: ToolCategoryType.Assemblies },
];

const ToolPickerModalContainer = ({
  open,
  tools = EMPTY_LIST,
  onExit,
  onAddTool,
  suppliers = EMPTY_MAP,
  toolGroups = EMPTY_MAP,
  topConnectors = EMPTY_MAP,
  onToggleModal,
  dispatchOnLoad,
  bottomConnectors = EMPTY_MAP,
  dispatchOnSearch,
  loadingDataState,
  dispatchOnRefreshTools,
  enabledToolCategoryTypes,
  dispatchOnChangeCategory,
  projectId,
}) => {
  const initialFilters = useMemo(
    () => ({
      [Filters.textSearch]: '',
      [Filters.toolGroupIds]: EMPTY_LIST,
      [Filters.topConnectorIds]: EMPTY_LIST,
      [Filters.bottomConnectorIds]: EMPTY_LIST,
      [Filters.projectId]: projectId,
    }),
    [projectId],
  );

  const [activeFilters, setFilters] = useState(initialFilters);
  const [activeCategory, setActiveCategory] = useState(toolCategories[0]);
  const enabledToolCategories = useMemo(
    () =>
      toolCategories.filter((category) =>
        enabledToolCategoryTypes.includes(category.value),
      ),
    [enabledToolCategoryTypes],
  );

  useEffect(() => {
    if (open) {
      dispatchOnSearch({
        [Filters.categoryId]: activeCategory.value,
        [Filters.projectId]: projectId,
      });
    } else {
      // Cleanup filters and category when closing
      setActiveCategory(toolCategories[0]);
      setFilters(initialFilters);
    }
  }, [open, activeCategory, dispatchOnSearch, projectId, initialFilters]);

  const handleSearch = useCallback(
    (filterValues) => {
      dispatchOnSearch({
        [Filters.textSearch]: filterValues[Filters.textSearch],
        [Filters.serialSearch]: filterValues[Filters.serialSearch],
        [Filters.categoryId]: activeCategory.value,
        [Filters.toolGroupIds]: filterValues[Filters.toolGroupIds].toArray(),
        [Filters.topConnectorIds]:
          filterValues[Filters.topConnectorIds].toArray(),
        [Filters.bottomConnectorIds]:
          filterValues[Filters.bottomConnectorIds].toArray(),
        [Filters.supplierSearch]: filterValues[Filters.supplierSearch],
        [Filters.projectId]: projectId,
      });
      setFilters(filterValues);
    },
    [dispatchOnSearch, activeCategory.value, projectId],
  );

  const onChangeCategory = useCallback(
    (newCategory) => {
      setActiveCategory(newCategory);
      dispatchOnChangeCategory(newCategory.value);
      setFilters(initialFilters);
    },
    [dispatchOnChangeCategory, initialFilters],
  );

  return (
    <ToolPickerModal
      open={open}
      tools={tools}
      onExit={onExit}
      suppliers={suppliers}
      onAddTool={onAddTool}
      onSearch={handleSearch}
      toolGroups={toolGroups}
      onEnter={dispatchOnLoad}
      toggleModal={onToggleModal}
      topConnectors={topConnectors}
      activeFilters={activeFilters}
      activeCategory={activeCategory}
      onChangeCategory={onChangeCategory}
      bottomConnectors={bottomConnectors}
      toolCategories={enabledToolCategories}
      loading={loadingDataState.isLoading()}
      onRefreshTools={dispatchOnRefreshTools}
      projectId={projectId}
    />
  );
};

export default compose(
  connect(
    (state) => ({
      toolGroups: getToolGroupsFromState(state),
      tools: getToolsSearchResultFromState(state),
      suppliers: getToolSuppliersFromState(state),
      topConnectors: getToolTopConnectorsFromState(state),
      bottomConnectors: getToolBottomConnectorsFromState(state),
      loadingDataState: getSummarizedDataStateFromState(
        state,
        TOOL_ACTIONS.REQUEST_TOOLS_SEARCH,
        TOOL_ACTIONS.TOOL_PICKER_MODAL_ON_LOAD,
        TOOL_ACTIONS.REQUEST_REFRESH_ALL_TOOLS_WITH_M3,
        TOOL_ACTIONS.REQUEST_TOOL_GROUPS_BY_CATEGORY,
        TOOL_ACTIONS.REQUEST_TOOL_GROUPS,
      ),
    }),
    {
      dispatchOnSearch: requestToolsSearch,
      dispatchOnLoad: toolPickerModalOnLoad,
      dispatchOnRefreshTools: requestRefreshToolsWithM3,
      dispatchOnChangeCategory: requestToolGroupsByCategory,
    },
  ),
  memo,
)(ToolPickerModalContainer);
